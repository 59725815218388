import React, { useEffect, useRef } from 'react';
import Modal from "./Modal";

export default function PlantDetailModal({ isOpen, onClose, plant }){
    const focusInputRef = useRef(null);

    useEffect(() => {
        if (isOpen && focusInputRef.current) {
        setTimeout(() => {
            focusInputRef.current.focus();
        }, 0);
        }
    }, [isOpen]);

    const contentImage1 = "images/" + plant.contentImage1;
    const contentImage2 = "images/" + plant.contentImage2;

    return (
        <Modal hasCloseBtn={true} isOpen={isOpen} onClose={onClose}>
            <div className="modal-body">
                <div className="bg-zinc-50 font-lato flex flex-col items-center">
                    <div className="text-emerald-700 border-b border-emerald-700 w-11/12 text-3xl py-2 m-2 flex justify-center">
                        {plant.name}
                    </div>
                </div>

                <div className="bg-zinc-50 font-lato pb-24 flex justify-center">
                    <div className="ml-10 mr-2 w-3/4 text-lg">
                        <div className="text-emerald-700 mt-5 mx-2 flex justify-start font-semibold">Description</div>
                        <div className="ml-2 mr-5 space-y-2 flex flex-col item-center text-justify">
                            <div>{plant.longDescription}</div>
                        </div>

                        <div className="text-emerald-700 mt-5 mx-2 flex justify-start font-semibold">Distinctive Features</div>
                        <div className="ml-2 mr-5 space-y-2 flex flex-col item-center text-justify">
                            <div>{plant.distinctiveFeatures}</div>
                        </div>

                        <div className="text-emerald-700 mt-5 mx-2 flex justify-start font-semibold">Environmental Impact</div>
                        <div className="ml-2 mr-5 space-y-2 flex flex-col item-center text-justify">
                            <div>{plant.environmentalImpact}</div>
                        </div>
                    </div>
                    <div className="text-emerald-700 mr-2 flex flex-col items-center">
                        <div className="pt-7">
                            <div className="m-5">
                                <img className="h-56 " src={contentImage1} alt="" />
                            </div>
                            <div className="m-5">
                                <img className="h-56 " src={contentImage2} alt="" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Modal>
    );
}