import React from "react";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";

export default function AboutInvasivePlant() {
  const navigate = useNavigate();

    return (
      <>
        <Header />

        <div className="bg-zinc-50 h-full flex flex-col">
          <div className="font-lato">
            <div className="text-emerald-700 text-3xl font-lato pt-5 flex justify-center">
              Invasive Plants
            </div>
            <div className="text-emerald-700 text-2xl mt-5 mx-64 flex justify-start">
              What are Invasive Plants and Its Impacts
            </div>
            <div className="text-lg w-8/12 mx-64 space-y-2 flex flex-col item-center text-justify">
              <div>
                Invasive plants are non-native plants to an environment. They
                are brought in either on accident or for ornamental purposes.
                Once introduced, they establish and spread quickly, outcompete
                the native plants for resources, and disrupt the local
                ecosystem. They are harm to the environment, economy and human
                health.
              </div>
            </div>

            <div className="text-emerald-700 text-xl mx-64 mt-4 pl-6  flex justify-start">
              - Harm to Crops and Livestock
            </div>
            <div className="text-base w-8/12 mx-64 pl-8 space-y-2 flex item-center text-justify">
              Invasive plants crowd out crops and rangeland forage, competing
              with nutrition in the soils. This can reduce crop yields. Many
              invasive plants are toxic to livestock. When they spread in
              pastures, it limits native plants grows and reduces the food for
              livestock.
            </div>

            <div className="text-emerald-700 text-xl mx-64 mt-8 pl-8 flex justify-start">
              - Landslide 
            </div>
            <div className="text-lg w-8/12 mx-64 pl-8 space-y-2 flex justify-center text-justify">
              When invasive plants like water hyacin cover the ground, there is
              very little root structure to bind the soils. It can cause erosion
              during flood than the areas covered with trees and shrubs, which
              provide more root structure.
            </div>

            <div className="text-emerald-700 text-xl mx-64 mt-8 pl-8 flex justify-start">
              - Poor Water Quality
            </div>
            <div className="text-lg w-8/12 mx-64 mt-2 pl-8 space-y-2 flex justify-center text-justify">
              Invasive plants provide less streamside cover and shade, it
              increases stream temperatures and reduces the dissolved oxygen
              level in the water. It can kill fishes and other aquatic life in
              the stream.
            </div>

            <div className="text-emerald-700 text-xl mx-64 mt-8 pl-8 flex justify-start">
              - Fire Risk
            </div>
            <div className="text-lg w-8/12 mx-64 pl-8 space-y-2 flex justify-center text-justify">
              Some invasive plants such as pampas grass, and eucalyptus generate
              higher fuel loads than native plants. When these plants invade,
              wildfires can be more frequent and sometimes catastrophic. Towns
              and wildlands may need decades to recover from these dangerous,
              costly fires.
            </div>

            <div className="text-emerald-700 text-2xl mx-64 mt-12 flex justify-start">
              How to Help Prevent the Spread of Invasive Plants
            </div>
            <div className="text-lg w-8/12 mx-64 space-y-2 flex justify-center text-justify">
              Invasive plants are not easy to remove so the best way to prevent
              the spread of them is by preventing them from being introduced
              into the environment. To prevent them from being introduced, plant
              native species instead of invasive species in your garden because
              invasive species can spread very quickly outside of your garden.
            </div>
          </div>
          <div className="my-10 flex justify-center">
            <button
              className="border-2 border-emerald-700 rounded-lg px-2 py-1 hover:bg-emerald-700 hover:text-white"
              onClick={()=>navigate("/plant-list")}>
              Check Invasive Plants Nearby
            </button>
          </div>
        </div>
      </>
    );
}