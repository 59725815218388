import React, { useState } from "react";
import PlantDetailModal from "./PlantDetailModal";

export default function PlantCard({plant}) {
    const [isDetailModalOpen, setDetailModalOpen] = useState(false);

    const image = "images/" + plant.imageUrl;
    
    const handleOpenDetailModal = () => {
        setDetailModalOpen(true);
    };

    const handleCloseDetailModal = () => {
        setDetailModalOpen(false);
    };

    return (
        <>
            <div className="border border-gray-200 w-64 rounded-lg drop-shadow-lg m-10" key={plant.id}>
              {/* <Link to={"/invasive-plant"} state={plant}> */}
                <button onClick={handleOpenDetailModal}>
                    <div>
                        <img className="rounded-t-lg object-cover h-64 w-full"
                        src={image} alt="" />
                    </div>
                    <div className="m-5">
                        <div className="text-lg font-lato text-emerald-700">
                        {plant.name}
                        </div>
                        <div className="text-gray-500">
                        {plant.shortDescription}
                        </div>
                    </div>
                </button>
               {/*  </Link> */}
            </div>

            <PlantDetailModal isOpen={isDetailModalOpen} onClose={handleCloseDetailModal} plant={plant} />
        </>
    );
}
