import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Pagination from "../components/Pagination";
import axios from 'axios';
import PlantCardRow from "../components/PlantCardRow";
import Papa from "papaparse";


export default function PlantList() {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(6);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        //loadMockData();
        loadData();
    }, [])

    function loadMockData() {
        axios.get('data/mock-data.json')
        .then(res => {
                setData(res.data);
                setLoading(false);
            })
            .catch(() => {
                alert('There was an error while retrieving the data')
            });
    }

    function loadData() {
        Papa.parse('data/invasive-plant-data.csv', {
            download: true,
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                setData(results.data);
                setLoading(false);
            },
            error: function(error) {
                console.log(error.message);

                // load mock data instead
                loadMockData();
            }
        });
    }

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
    const nPages = Math.ceil(data.length / recordsPerPage)

    return (
        <>
            <Header />
            <div className="bg-zinc-50 h-full flex flex-col">
                <div className="flex flex-col items-center">
                    <div className=" text-emerald-700 text-3xl pt-10 pb-2 font-lato flex justify-center ">
                        Invasive Plants Around Bay Area
                    </div>
                </div>

                <div className="mx-8 flex flex-col items-center">
                {
                    (currentRecords.length <= 3) && <PlantCardRow key="1" plantItems={currentRecords} />
                    
                }
                {
                    (currentRecords.length > 3) && 
                    <>
                        <PlantCardRow rowId="1" plantItems={currentRecords.slice(0,3)} />
                        <PlantCardRow rowId="2" plantItems={currentRecords.slice(3,currentRecords.length)} />
                    </>
                }
                </div>

                <div className=" mb-10 flex flex-col items-center">
                    <Pagination
                        nPages={nPages}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </div>
            </div>
        </>
    );
}