import React from "react";
import Header from "../components/Header";

export default function About() {
     return (
      <>
        <Header />
        
        <div class="bg-zinc-50 h-screen">
          <div class="font-lato flex flex-col items-center">
            <div class="flex justify-between">

            <div class="m-10 pt-14">
                <img class="object-cover w-64" src="images/aboutme.jpg" alt="" />
              </div>
              
              <div>
                <div class="text-emerald-700 text-2xl font-lato p-5 flex justify-center mt-10">
                  About This Website
                </div>
                <div class="ml-1">
                  <div class="text-lg text-justify w-[36rem] my-2">
                    <b class="text-emerald-700">Pick-Your-Plant</b> is a non-profit website dedicated to educating the general public about invasive plants around San Francisco Bay Area. The listed invasive plants are selected from <a class="text-emerald-700" href="https://www.cal-ipc.org/" target="_blank" rel="noreferrer"><i>California Invasive Plant Council (Cal-IPC)</i></a> inventory. This website is designed and created by Helen Lee.  
                  </div>
                  <div class="text-lg text-justify w-[36rem] my-4">
                    Helen Lee is currently going to a local high school in bay area. She has a passion for nature and environment. She was a Youth Steward at <a class="text-emerald-700" href="https://www.grassrootsecology.org/" target="_blank" rel="noreferrer"><i>Grassroots Ecology</i></a> and currently continues to volunteer there. She is also a board member of the Environmental Club at her school.                   
                  </div>
                </div>

              </div>
              
              
            </div>
          </div>
        </div>
      </>
    );
}