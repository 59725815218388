import React from "react";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";


const imageStyles = {
    width: "100%", 
    height: "100%", 
    objectFit: "cover"
    
};

const captionText = {
    textAlign: "right",
    color: "#767676",
    float: "right",
    paddingRight: "30px"
}

export default function HomePage() {
    const navigate = useNavigate(); 

    return (
        <>
            <Header />

            {/* Intro message section */}
            <div className="bg-zinc-50 h-96">
                <img src="images/babys-breath.jpeg" style={imageStyles} alt="" />
                <span style={captionText}>Baby's Breath is beautiful in bouquets, but it can be invasive. Credit: liuyushan/Getty Images.</span>
            </div>
            <br / >
            <div className="bg-zinc-50 pb-40">
                <div className="bg-zinc-50 flex flex-col items-center justify-center">
                    <div className="text-emerald-700 mt-8 mb-5 flex justify-center">
                        <div className="text-3xl font-lato space-y-2 flex flex-col justify-center items-center">
                            <div>
                            Educating General Public on Local Invasive
                            </div>
                            <div>
                            Plants Around San Francisco Bay Area
                            </div>
                        </div>
                    </div>
                    <div class="text-lg text-justify w-2/3 my-2">
                  There are many different types of plants that surround us everyday. Some of them we take notice because of how beautiful they are, and some we avoid either for appearance or safety. However, not every plant we see that is beautiful or common is supposed to be there. Some plants you see everyday and think are beautiful are actually invasive species and are actively doing harm to the ecosystem they inhabit.
                    </div>
                    <br/>
                    <div className="my-3">
                        <button className="border-2 border-emerald-700 rounded-lg px-2 py-1 hover:bg-emerald-700 hover:text-white" onClick={()=>navigate("/about-invasive-plants")}>
                        Learn More
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}