import React from "react";  
import { Link } from "react-router-dom";

export default function Header() {
    
    return (
        
        <>
            {/* Logo and Top Menu section */}
            <div className="border-b-2 border-gray-200 flex justify-between items-center">
                {/* logo */}
                <div className="ml-3 px-4 p-3 text-xl flex justify-between">
                    <div className="flex items-center">
                        <div>
                            <Link to="/">
                                <img className="h-20 w-full object-cover md:h-full md:w-20" src="images/logo.jpg" alt="" />
                            </Link>
                        </div>
                        <div className="text-purple-500 font-cormorant font-bold ml-1">
                        Pick
                        </div>
                        <div className="text-emerald-700 font-cormorant font-bold">
                        Your
                        </div>
                        <div className="text-purple-500 font-cormorant font-bold">
                        Plant
                        </div>
                    </div>
                </div>
                {/* menu */}
                <div className="pl-1 pr-8 mt-7">
                    <div className="flex items-center">
                        <div className="text-emerald-700 text-lg p-2 ml-10 mt-5 mr-10">
                            <Link to="/">Home</Link>
                        </div>
                        <div className="text-emerald-700 text-lg p-2 ml-10 mt-5 mr-10">
                            <Link to="/about-invasive-plants">What is Invasive Plants</Link>
                        </div>
                        <div className="text-emerald-700 text-lg p-2 ml-10 mt-5 mr-10">
                            <Link to="/plant-list">Invasive Plant List</Link>
                        </div>
                        <div className="text-emerald-700 text-lg p-2 ml-10 mt-5 mr-10">
                            <Link to="/about">About</Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* End of Logo and Top Menu section */}


        </>
    );
}