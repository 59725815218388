import React from "react";
import PlantCard from "./PlantCard";

export default function PlantCardRow(props) {

    return (
        <>
            <div key={props.rowId} className=" flex flex-between">
                {
                    props.plantItems.map(item => 
                        <PlantCard key={item.id} plant={item} />
                    )
                }
            </div>
        
        </>
    );

}