import './App.css';
import HomePage from './views/HomePage';
import AboutInvasivePlant from './views/AboutInvasivePlant';
import PlantList from './views/PlantList';
import About from './views/About';
import {BrowserRouter,Routes,Route} from "react-router-dom"; 



function App() {
  return (
    <>
    
       <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route index element={<HomePage />} />
            <Route exact path="/about-invasive-plants" element={<AboutInvasivePlant />} />
            <Route exact path="/plant-list" element={<PlantList />} />
            <Route exact path="/about" element={<About />} />  
        </Routes> 
      </BrowserRouter>
    </>

  )
}

export default App;
